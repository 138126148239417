import Cookies from 'universal-cookie';
import { createSlice } from '@reduxjs/toolkit';
import config from '@utils/config';

const cookies = new Cookies();

export const initialState = {
  isLoggedIn: false,
  username: null,
  currentUser: {
    token: null,
    jwtToken: null,
  },
  userPermission: [],
  systemConfiguration: [],
};

const refreshTokenIdle = () => {
  const expired = new Date(Date.now() + config.refreshAuthLifetime);
  cookies.set('usabletoken', expired.toISOString(), { path: '/', expires: expired });
};

export const app = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUserLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload.loggedIn;
      state.currentUser = {
        ...state.currentUser,
        token: action.payload.token,
        jwtToken: action.payload.token,
        username: action.payload.username,
      };

      cookies.set('intools_jwt_token', action.payload.token, { path: '/' });
      refreshTokenIdle();
    },
    setJWTToken: (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        jwtToken: action.payload,
      };

      cookies.set('intools_jwt_token', action.payload, { path: '/' });
    },
    resetAuth: (state) => {
      cookies.remove('intools_jwt_token', { path: '/' });
      cookies.remove('usabletoken', { path: '/' });
      return initialState;
    },
    setUserPermission: (state, action) => {
      state.userPermission = action.payload;
    },
    setSystemConfiguration: (state, action) => {
      state.systemConfiguration = action.payload;
    },
  },
});

/**
 * Validates auth tokens and refreshes if needed
 * @param {boolean} refresh - Whether to refresh the token
 * @returns {Promise<Object|null>} Auth state or null if invalid
 */
export const refreshAuthState = (isIdle) => async (dispatch, getState) => {
  try {
    if (isIdle) {
      return refreshTokenIdle();
    }

    const jwtToken = cookies.get('intools_jwt_token'),
      usableToken = cookies.get('usabletoken');

    if (!isValidTokens(jwtToken, usableToken)) {
      dispatch(resetAuth());
      return null;
    }

    const state = getState();
    return state.auth;
  } catch (error) {
    console.error('Auth refresh failed:', error);
    dispatch(resetAuth());
    return null;
  }
};

// Helper function to validate tokens
const isValidTokens = (jwtToken, usableToken) => {
  if (!jwtToken || !usableToken) {
    return false;
  }

  const usableTime = new Date(usableToken).getTime();
  const currentTime = Date.now();

  if (usableTime < currentTime) {
    return false;
  }

  return true;
};

export const { setUserLoggedIn, setJWTToken, setUserPermission, setSystemConfiguration, resetAuth } = app.actions;
export const hasLoggedIn = (state) => state.auth.isLoggedIn;
export const permission = (state) => state.auth.userPermission;
export const configuration = (state) => state.auth.systemConfiguration;
export const getUsername = (state) => state.auth.currentUser.username;

export default app.reducer;
