import { useState, useEffect, useCallback } from 'react';

// Constants
const EVENTS = ['mousemove', 'mousedown', 'keydown', 'touchstart', 'scroll'];
const DEBOUNCE_DELAY = 250; // ms

/**
 * Hook to detect user activity/inactivity
 * @param {number} timeout - Idle timeout in milliseconds
 * @returns {boolean} - True if user is idle
 */
const useActivityDetector = (timeout = 10000) => {
  const [isIdle, setIsIdle] = useState(false);

  // Memoized timer reset function
  const resetTimer = useCallback(() => {
    let timeoutId;

    // Debounced function
    let debounceTimer;
    return () => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        setIsIdle(false);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => setIsIdle(true), timeout);
      }, DEBOUNCE_DELAY);
    };
  }, [timeout]);

  useEffect(() => {
    try {
      const handleActivity = resetTimer();

      // Add event listeners
      EVENTS.forEach((event) => {
        document.addEventListener(event, handleActivity);
      });

      // Initial timer
      handleActivity();

      // Cleanup
      return () => {
        EVENTS.forEach((event) => {
          document.removeEventListener(event, handleActivity);
        });
      };
    } catch (error) {
      console.error('Activity detector error:', error);
      setIsIdle(false);
    }
  }, [resetTimer]);

  return isIdle;
};

export default useActivityDetector;
